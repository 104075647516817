"use client";

import { getExperienceRecomendations } from "@ApiReq";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../use-store.hooks";
import { discoverActions } from "@store";
import { getLocationByIP } from "@next/ApiReq/location/location-api";
import { useParams } from "next/navigation";

export default function useRecommendation({
  id,
  activityId,
}: {
  id: string;
  activityId?: string;
}) {
  const [count, setCount] = useState(0);
  const [params, setParams] = useState({
    limit: 12,
    offset: 0,
  });
  const [recomendations, setRecomendations] = useState<
    {
      id: string;
      heading: string;
      subtitle: string;
      url: string;
      images: { url: string; alt: string; title: string }[];
    }[]
  >([]);
  const [users, setUsers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noContentFound, setNoContentFound] = useState(false);
  const { location } = useAppSelector((state) => state.discover);
  const dispatch = useAppDispatch();
  const urlParams = useParams<{ lng: string }>();

  const handleGetExperienceRecommendation = useCallback(
    (lat?: number, lng?: number, offset?:number) => {
      if (lat && lng) {
        setIsLoading(true);

        getExperienceRecomendations({
          id: id, //experienceData?.id,
          limit: params.limit,
          offset: offset as number,
          latitude: lat ? lat : Number(location?.lat),
          longitude: lng ? lng : Number(location?.lng),
          activityId: activityId,
          locale: urlParams.lng ?? "",
        })
          .then((res) => {
            const { data, pagination } = res;
            setCount(pagination?.total);

            const structRecommendation = data.map((recommendation: any) => {
              const structImages = recommendation?.assets?.images.map(
                (image: any) => {
                  return {
                    url: image?.file ?? "",
                    alt: image?.alt ?? "",
                    title: image?.title ?? "",
                  };
                }
              );
              const singleImage =
                structImages.length > 0 ? [structImages[0]] : [];
              return {
                activityId: recommendation?.activityId ?? "",
                heading: recommendation?.company ?? "",
                subtitle: `${recommendation?.city ?? ""} ${
                  recommendation?.country ?? ""
                }`,
                url: recommendation?.url ?? "",
                images: singleImage,
              };
            });

            if (structRecommendation.length === 0) {
              setNoContentFound(true);
            } else {
              setNoContentFound(false);
            }
            const mergeNewRecomendations = [
              ...recomendations,
              ...structRecommendation,
            ];

            const idMap = new Map();
            // Iterate over the array to populate the idMap
            for (const item of mergeNewRecomendations) {
              if (!idMap.has(item.activityId)) {
                idMap.set(item.activityId, item);
              }
            }
            const arrayUniqueByKey = Array.from(idMap.values());

            setRecomendations(arrayUniqueByKey);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            setNoContentFound(true);
            setRecomendations([]);
          });
      }
    },
    [
      activityId,
      id,
      location?.lat,
      location?.lng,
      params.limit,
      recomendations,
      urlParams.lng,
    ]
  );

  const handleMore = () => {
    const newOffset = params.offset + 1;
    setParams((prevProp) => ({
      ...prevProp,
      offset: newOffset,
    }));
    handleGetExperienceRecommendation(
      Number(location?.lat),
      Number(location?.lng),
      newOffset
    );
  };
  const handleShowLess = () => {
    setRecomendations([]);
    setParams((prevParams) => ({ ...prevParams, offset: 1 }));
  };
  const hasMoreRecommendations = () => {
    const { offset, limit } = params;
    const offsetValue = offset + 1;
    const currentLimit = offsetValue * limit;
    if (currentLimit < count) {
      return true;
    }
    return false;
  };

  const handleLocation = (value: {
    value: string;
    lat: string;
    lng: string;
  }) => {
    dispatch(discoverActions.setLocation(value));
  };
  const handleSearch = () => {
    handleGetExperienceRecommendation(
      Number(location?.lat),
      Number(location?.lng),
      params.offset
    );
  };
  const handleLocationByIP = useCallback(() => {
    getLocationByIP()
      .then((res) => {
        const loc = res?.data?.loc ?? "";
        const coords = loc.split(",");
        if (coords && coords.length > 0) {
          handleGetExperienceRecommendation(coords[0], coords[1],params.offset);
        }
      })
      .catch((err) => ({}));
  }, [handleGetExperienceRecommendation,params.offset]);

 
  const hasFetchedData = useRef<boolean>(false);
  useEffect(() => {
    const fetchData = async () => {
      if (navigator.geolocation) {
        try {
          const position = await new Promise<GeolocationPosition>(
            (resolve, reject) => {
              navigator.geolocation.getCurrentPosition(resolve, reject);
            }
          );
          const { latitude, longitude } = position.coords;
          const currentLocation = { value: "", lat: latitude, lng: longitude };
          await dispatch(discoverActions.setLocation(currentLocation));

          handleGetExperienceRecommendation(latitude, longitude,params.offset); // Call handleSearch after setting the location
        } catch (error) {
          console.error("Error retrieving geolocation:", error);
          handleLocationByIP();
        }
      } else {
        console.warn("Geolocation not supported");
        handleLocationByIP();
      }
    };
    const isLocationExists = location?.lat && location?.lng;
   
    if (!isLocationExists) {
     
      if (!hasFetchedData?.current && (id || activityId)) {
        hasFetchedData.current = true;
        fetchData();
       
      }
    } else {
     
      if (!hasFetchedData?.current && (id || activityId)) {
        hasFetchedData.current = true;
        handleGetExperienceRecommendation(
          Number(location?.lat),
          Number(location?.lng),
          params.offset
        );
      }
    }
  }, [
    dispatch,
    handleGetExperienceRecommendation,
    handleLocationByIP,
    location?.lat,
    location?.lng,
    id,
    activityId,
    params.offset
  ]);

  return {
    recomendations,
    handleMore,
    handleShowLess,
    hasMoreRecommendations,
    count,
    params,
    isLoading,
    location,
    handleLocation,
    users,
    setUsers,
    handleSearch,
    noContentFound,
  };
}
