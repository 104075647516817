import { REQUEST_STATUS } from "@constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { myContactsSliceState } from "./my-contacts.types";
import { getGroups,getContacts } from "./my-contacts-api";
export const myContactsInitialState: myContactsSliceState = {
  getGroupsStatus:REQUEST_STATUS.IDEL,
    groups:[],
    getContactsStatus:REQUEST_STATUS.IDEL,
    contacts:[],
    errorMessage:""
}
const myContactsSlice = createSlice({
    name: "my-contacts",
    initialState: myContactsInitialState,
    reducers: {},
    extraReducers: (builder) => {
          // GET GROUPS
          builder.addCase(getGroups.pending, (state, action) => {
            state.getGroupsStatus = REQUEST_STATUS.LOADING;
          });
          builder.addCase(getGroups.fulfilled, (state, action: any) => {
            const res = action?.payload;
            if (res?.result) {
                state.getGroupsStatus = REQUEST_STATUS.SUCCESS;
              state.groups = res?.result?.groups;
    
            } else {
                state.errorMessage = res.message;
              state.getGroupsStatus = REQUEST_STATUS.FAILURE;
            }
          });
        
          builder.addCase(getGroups.rejected, (state, action:any) => {
            const res = action?.payload;
            state.errorMessage = res?.message;
            state.getGroupsStatus = REQUEST_STATUS.FAILURE;
          });

           // GET CONTACTS
           builder.addCase(getContacts.pending, (state, action) => {
            state.getContactsStatus = REQUEST_STATUS.LOADING;
          });
          builder.addCase(getContacts.fulfilled, (state, action: any) => {
            const res = action?.payload;
            if (res?.result) {
                state.getContactsStatus = REQUEST_STATUS.SUCCESS;
              state.contacts = res?.result?.contacts;
    
            } else {
                state.errorMessage = res.message;
              state.getContactsStatus = REQUEST_STATUS.FAILURE;
            }
          });
        
          builder.addCase(getContacts.rejected, (state, action:any) => {
            console.log(action)
            const res = action?.payload;
            state.errorMessage = res?.message;
            state.getContactsStatus = REQUEST_STATUS.FAILURE;
          });
    }
});
export const myContactsActions = myContactsSlice.actions;
export const myContactsReducer = myContactsSlice.reducer;