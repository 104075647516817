"use client";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams,
} from "next/navigation";
import { getExperiencesBySearch } from "@ApiReq";
import { useAppSelector, useAppDispatch } from "../use-store.hooks";
import { discoverActions } from "@store";
import { getGeocode } from "use-places-autocomplete";
import key from "config/key";
function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}
export default function useLocationPartnerSearch() {
  const [users, setUsers] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [params, setParams] = useState({
    limit: 12,
    offset: 0,
  });
  const [address, setAddress] = useState<{
    latitude: null | string;
    longitude: null | string;
    placeId: null | string;
  }>({
    latitude: null,
    longitude: null,
    placeId: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [noContentFound, setNoContentFound] = useState(false);
  const [recomendations, setRecomendations] = useState<
    {
      heading: string;
      subtitle: string;
      images: { url: string; alt: string; title: string }[];
      url: string;
    }[]
  >([]);
  const [loaded, setLoaded] = useState(false);
  const [isMapLoaded, seIsMapLoaded] = useState(false);
  // const loaded = React.useRef(false);
  if (typeof window !== "undefined" && !loaded) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${key.NEXT_PUBLIC_GOOGLE_PLACES_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }
    setLoaded(true);
    // loaded.current = true;
  }
  if (
    typeof window !== "undefined" &&
    window.google &&
    window.google.maps &&
    !isMapLoaded
  ) {
    seIsMapLoaded(true);
  }
  const { location } = useAppSelector((state) => state.discover);
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();
  const router = useRouter();
  const urlParams = useParams<{ lng: string }>();
  const pathname = usePathname();

  const getRecommendation = useCallback(() => {
    if (address.latitude && address.longitude) {
      setIsLoading(true);
      getExperiencesBySearch({
        latitude: Number(address.latitude ?? ""),
        longitude: Number(address.longitude ?? ""),
        limit: params.limit,
        offset: params.offset,
        locale: urlParams.lng ?? "",
      })
        .then((res) => {
          const { data, pagination } = res?.data;
          setCount(pagination?.total);
          const structRecommendation = data.map((recommendation: any) => {
            const structImages = recommendation?.assets?.images.map(
              (image: any) => {
                return {
                  url: image?.file ?? "",
                  alt: image?.alt ?? "",
                  title: image?.title ?? "",
                };
              }
            );
            const singleImage =
              structImages.length > 0 ? [structImages[0]] : [];
            return {
              id: recommendation?.id,
              heading: recommendation?.name ?? "",
              subtitle: recommendation?.category?.name ?? "",
              images: singleImage,
              url: recommendation?.url,
            };
          });
          const mergeNewRecomendations = [
            ...recomendations,
            ...structRecommendation,
          ];

          const idMap = new Map();
          // Iterate over the array to populate the idMap
          for (const item of mergeNewRecomendations) {
            if (!idMap.has(item.id)) {
              idMap.set(item.id, item);
            }
          }
          const arrayUniqueByKey = Array.from(idMap.values());
          setRecomendations(arrayUniqueByKey);
          setIsLoading(false);
          if (structRecommendation.length === 0) {
            setNoContentFound(true);
          } else {
            setNoContentFound(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setNoContentFound(true);
          setRecomendations([]);
        });
    }
  }, [
    address.latitude,
    address.longitude,
    params.limit,
    params.offset,
    recomendations,
    urlParams.lng,
  ]);

  const getAddressFromCoordinates = async (placeId: string) => {
    try {
      if (window.google && window.google.maps) {
        const geocoderRequest = {
          placeId: placeId,
        };
        const results = await getGeocode(geocoderRequest);
        if (results && results.length > 0) {
          let country = "";
          let city = "";
          const addressComponents = results[0]?.address_components || [];
          addressComponents.forEach((component) => {
            if (component.types.includes("country")) {
              country = component.long_name;
            }
            if (component.types.includes("locality")) {
              city = component.long_name;
            }
          });
          const address = `${city}, ${country}`;

          return address;
        } else {
          return "";
        }
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }
  };
  function getAddressFromLatLng(
    latitude: number,
    longitude: number
  ): Promise<{ address: string; place_id: string }> {
    return new Promise((resolve, reject) => {
      if (!window.google || !window.google.maps) {
        reject("Google Maps API is not loaded");
        return;
      }

      const geocoder = new window.google.maps.Geocoder();
      const latlng = { lat: latitude, lng: longitude };

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results?.[0]) {
            let country = "";
            let city = "";
            const addressComponents = results[0]?.address_components || [];
            addressComponents.forEach((component) => {
              if (component.types.includes("country")) {
                country = component.long_name;
              }
              if (component.types.includes("locality")) {
                city = component.long_name;
              }
            });
            const address = `${city}, ${country}`;
            const addrData = {
              address: address,
              place_id: results?.[0]?.place_id || "",
            };

            resolve(addrData);
          } else {
            reject("No results found");
          }
        } else {
          reject("Geocoder failed due to: " + status);
        }
      });
    });
  }
  const setDefaultAddress = useCallback(async () => {
    if (navigator.geolocation) {
      try {
        const position = await new Promise<GeolocationPosition>(
          (resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          }
        );

        const { latitude, longitude } = position.coords;
        getAddressFromLatLng(latitude, longitude)
          .then((data: { address: string; place_id: string }) => {
            dispatch(
              discoverActions.setLocation({
                value: data.address,
                lat: latitude,
                lng: longitude,
                placeId: data.place_id,
              })
            );
          })
          .catch((error: string) => {
            dispatch(
              discoverActions.setLocation({
                value: "",
                lat: null,
                lng: null,
                placeId: null,
              })
            );
          });
      } catch (error) {
        dispatch(
          discoverActions.setLocation({
            value: "",
            lat: null,
            lng: null,
            placeId: null,
          })
        );
      }
    }
  }, [dispatch]);
  const latitude = searchParams.get("latitude") || location?.lat;
  const longitude = searchParams.get("longitude") || location?.lng;
  const placeId = searchParams.get("placeId") || location?.placeId;
  useEffect(() => {
    setParams({
      limit: 12,
      offset: 0,
    });
    setAddress({
      latitude,
      longitude,
      placeId,
    });
    setRecomendations([]);
    setNoContentFound(true);
  }, [latitude, longitude, placeId, urlParams.lng]);
  const hasFetchedData = useRef<boolean>(false);
  const urlPlaceId = searchParams.get("placeId")
  useEffect(() => {
    const section = document.getElementById("recommendations");
    // Scroll to the section if it exists
    if (section) {
     
        section.scrollIntoView({ behavior: "smooth" });
       
      
    }
  },[urlPlaceId])
  useEffect(() => {
    
    if (isMapLoaded && address?.placeId) {
      if (!hasFetchedData?.current) {
        getAddressFromCoordinates(address?.placeId as string).then((addr) => {
          dispatch(
            discoverActions.setLocation({
              value: addr,
              lat: address?.latitude,
              lng: address?.longitude,
              placeId: address?.placeId,
            })
          );
        });
        hasFetchedData.current = true;
      }
    } else {
      if (pathname === "/explore" && !location?.lat) {
        if (!hasFetchedData?.current) {
          setDefaultAddress();
          hasFetchedData.current = true;
        }
      }
    }
  }, [
    address,
    isMapLoaded,
    dispatch,
    setDefaultAddress,
    location?.lat,
    pathname,
  ]);

  useEffect(() => {
    getRecommendation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleMore = () => {
    setParams((prevProp) => ({
      ...prevProp,
      offset: prevProp.offset + 1,
    }));
  };
  const handleShowLess = () => {
    setRecomendations([]);
    setParams((prevParams) => ({ ...prevParams, offset: 1 }));
  };
  const hasMoreRecommendations = () => {
    const { offset, limit } = params;
    const offsetValue = offset + 1;
    const currentLimit = offsetValue * limit;
    if (currentLimit < count) {
      return true;
    }
    return false;
  };
  const handleLocation = (value: {
    value: string;
    lat: string;
    lng: string;
  }) => {
    dispatch(discoverActions.setLocation(value));
  };
  const handleSearch = () => {
    router.push(
      `/explore/search?latitude=${location?.lat}&longitude=${location?.lng}&placeId=${location?.placeId}`
    );
  };
  return {
    recomendations,
    handleMore,
    handleShowLess,
    hasMoreRecommendations,
    count,
    params,
    handleSearch,
    location,
    setLocation: handleLocation,
    users,
    setUsers,
    isLoading,
    noContentFound,
  };
}
