import { environment, activities } from "@config";
// import { axiosInstance } from "@hooks";
import ActivityBySeoUrl from "../mock/activities/ActivityBySeoUrl.json";
import axios from "axios";
export const getActivitiesBySeoUrl = async ({
  seoUrl,
  locale,
}: {
  seoUrl: string;
  locale: string;
}) => {
  // Use mock data if applicable
  if (activities.FETCH_ACTIVITIES_BY_URL.useMock) {
    return ActivityBySeoUrl;
  }

  // Construct the base URL
  let baseUrl = environment.apiKey;
  baseUrl = `${baseUrl}${activities.FETCH_ACTIVITIES_BY_URL.url}?url=${seoUrl}`;

  try {
    // Fetch data with caching for 30 seconds
    const res = await fetch(baseUrl, {
      headers: {
        'Accept-Language': locale,
      },
      next: { revalidate: 30 }, // Caches response for 30 seconds
    });

    if (!res.ok) {
      throw new Error('Failed to fetch activities data');
    }

    // Parse and return the JSON data
    const data = await res.json();
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
// TODO: Remove this if caching working with fetch
// export const getActivitiesBySeoUrl = async ({
//   seoUrl,
//   locale,
// }: {
//   seoUrl: string;
//   locale: string;
// }) => {
//   let baseUrl = "";
//   if (activities.FETCH_ACTIVITIES_BY_URL.useMock) {
//     return ActivityBySeoUrl;
//   } else {
//     baseUrl = environment.apiKey;

//     baseUrl = `${baseUrl}${activities.FETCH_ACTIVITIES_BY_URL.url}?url=${seoUrl}`;
//     return axios
//       .get(baseUrl, {
//         headers: {
//           "Accept-Language": locale,
//         },
//       })
//       .then((res) => Promise.resolve(res?.data))
//       .catch((err) => Promise.reject(err));
//   }
// };

export const addNewSuggestionForActivity = async ({
  id,
  data,
}: {
  id?: string;
  data: { name: string; email: string; website: string };
}) => {
  let baseUrl = environment.apiKey;
  baseUrl = `${baseUrl}/explore/api/v1/activities/suggestion`;
  // return await axios.get(baseUrl);
  return axios
    .post(
      baseUrl,
      {
        id,
        ...data,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((res) => Promise.resolve(res?.data))
    .catch((err) => Promise.reject(err));
};
