import axios from "axios";
import { environment } from "@next/config";
import { authActions } from "@store";
import { createStore } from "@next/store";

export function invalidRefreshToken() {
  localStorage.clear();
  window.location.href = "/login";
  const store = createStore;
  store.dispatch(authActions.logout());
}
/**
 * backendAxios
 *
 * Feature:
 * - Provides an axios instance with baseURL set to apiKey provided by environment.
 */

export const domain: string = environment.apiKey;
export const url: string = domain;
export const axiosInstance = axios.create({});
export const publicUrl = axios.create({
  baseURL: url,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});
export const privateUrl = axios.create({
  baseURL: url,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

axiosInstance.interceptors.request.use((request) => {
  const locale: any = (localStorage.getItem("locale") ?? "").toLowerCase();
  if (locale && request.headers) {
    request.headers["Accept-Language"] = locale;
  }
  return request;
});
//define request interceptors
privateUrl.interceptors.request.use((request) => {
  const accesToken: any = localStorage.getItem("token");
  if (localStorage.getItem("token") && request.headers) {
    request.headers["Authorization"] = "Bearer" + " " + accesToken;
  }
  return request;
});

//define response interceptors
privateUrl.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  async (err) => {
    if (!err.response) {
      window.scrollTo(0, 0);
    }

    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await privateUrl.post("/Accounts/RefreshToken", {
            refreshToken: localStorage.getItem("refreshToken"),
            accessToken: localStorage.getItem("token"),
          });
          const { result } = rs.data;

          localStorage.setItem("token", result?.token?.accessToken);
          localStorage.setItem("refreshToken", result?.token?.refreshToken);
          return privateUrl(originalConfig);
        } catch (_error: any) {
          console.log("Error: fetching refresh token from API: ", _error?.response?.status);
          if (_error.response.status == 400) {
            invalidRefreshToken();
          }
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);
