export const experiences = {
  GET_EXPERIENCES_BY_ID: {
    url: "/explore/api/v1/experiences", // /ecaaa6fa-c65b-4124-998c-355892541025
    useMock: false,
  },

  GET_EXPERIENCES_BY_URL: {
    url: "/explore/api/v1/experiences", //?url=experiences/husky-tour
    useMock: false,
  },

  GET_EXPERIENCES_BY_SEARCH: {
    url: "/explore/api/v1/experiences/search",
    useMock: false,
  },

  GET_EXPERIENCES_RECOMMENDATIONS: {
    url: "/explore/api/v1/experiences", // /id/recomendation
    useMock: false,
  },
};
export const activities = {
  FETCH_ACTIVITIES_BY_URL: {
    url: "/explore/api/v1/activities", //?url=experiences/husky-tour/husky-tours-gastein
    useMock: false,
  },
};
export const providers = {
  FETCH_PROVIDERS_BY_ID: {
    url: "/explore/api/v1/providers", // /id
    useMock: false,
  },
};
export const interests = {
  FETCH_USER_INTERETS_BY_EXPERIENCE_ID: {
    url: "/explore/api/v1/interests/experience", // /id
    useMock: false,
  },
  DELETE_INTEREST: {
    url: "/explore/api/v1/interests", // /id
    useMock: false,
  },
  UPDATE_INTEREST: {
    url: "/explore/api/v1/interests", // /id
    useMock: false,
  },
};