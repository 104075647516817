"use client";

import React, { useState } from "react";
import key from "config/key";

function loadScript(
  src: string,
  position: HTMLElement | null,
  id: string,
  callback: () => void
) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.async = true;
  script.id = id;
  script.src = src;
  script.defer = true; // Also consider adding defer attribute for better performance
  script.onload = callback; // Set callback to be invoked when script is loaded

  position.appendChild(script);
}

export const useGooglePlaceApi = () => {
  const [loaded, setLoaded] = useState(false);
  // const loaded = React.useRef(false);

  const loadGoogleMapsScript = () => {
    if (typeof window !== "undefined" && !loaded) {
      if (!document.querySelector("#google-maps")) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${key.NEXT_PUBLIC_GOOGLE_PLACES_KEY}&libraries=places`,
          document.querySelector("head"),
          "google-maps",
          () => {
            setLoaded(true);
          }
        );
      }
    }
  };

  React.useEffect(() => {
    loadGoogleMapsScript();
  }, []);

  return {
    loaded,
  };
};
