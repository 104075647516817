import { privateUrl } from "@hooks"
import { addMomentAssetsProps,removeMomentAssetsProps } from "./moments.types"
export const addMomentAssets =async ({
    loginToken,
    momentId,
    assets
}:addMomentAssetsProps) => {
    return await privateUrl
      .post(`Moments/UploadAssets?momentId=${momentId}`, assets, {
        headers: {
          Authorization: loginToken,
          "Content-Type": "multipart/form-data",
        }
      })
}
export const removeMomentAssets =async ({
  loginToken,
  id
}:removeMomentAssetsProps) => {
  return await privateUrl
    .post(`Moments/DeleteAssets`, {id:id}, {
      headers: {
        Authorization: loginToken,
      }
    })
}
export const updateMoment =  async ({ loginToken,momentId, memoryId, location, date, hashtags, caption  }:
  {
    loginToken: string,
    memoryId: any,
    momentId:string;
    location: string,
    date: string,
    hashtags: string[],
    caption: string
  }) => {
  return await privateUrl
    .put(`Moments/${momentId}`, { memoryId,location,date,hashtags,caption }, {
      headers: {
        Authorization: loginToken,
      }
    })
}