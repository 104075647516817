import { string, object } from "yup";
import key from "../../config/key"
/**
 * contains all the validated environment variables.
 *
 * Reason:
 * This help prevents the application to start without environment variables. If not used you may still find the
 * error but a bit late.
 */

export const environment = object()
  .shape({
    apiKey: string().required(),
    mockApiKey: string().required(),
  })
  .validateSync({
    apiKey:key.NEXT_PUBLIC_API_KEY,
    mockApiKey:key.NEXT_PUBLIC_MOCK_API_KEY
  });
