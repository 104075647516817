import { getRatingProps, addRatingProps } from "./rating.types";
import { environment } from "@config";
import axios from "axios";
export const getRatings = async ({ userId, pageSize }: getRatingProps) => {
  let baseUrl = environment.apiKey;
  baseUrl = `${baseUrl}/explore/api/v1/experiences/unrated/${userId}?offset=0&limit=${pageSize}`;
  return await axios
    .get(baseUrl)
    .then((res) => Promise.resolve(res?.data))
    .catch((err) => Promise.reject(err));
};
export const addRating = async (data: addRatingProps) => {
  let baseUrl = environment.apiKey;
  baseUrl = `${baseUrl}/explore/api/v1/interests`;
  console.log("add rating baseurl", baseUrl)
  return await axios.post(baseUrl, { ...data }).then((res) => Promise.resolve(res?.data))
  .catch((err) => Promise.reject(err));
};
