import { REQUEST_STATUS } from "@constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MomentsSliceState } from "./moments.types";

export const momentsInitialState: MomentsSliceState = {
    addMomentAssetsStatus:REQUEST_STATUS.IDEL,
    momentAssets:[],
    errorMessage:""
}
const momentsSlice = createSlice({
    name: "moments",
    initialState: momentsInitialState,
    reducers: {},
    extraReducers: (builder) => {
        // add_MEMORY
        // builder.addCase(addMomentAssets.pending, (state, action) => {
        //   state.addMomentAssetsStatus = REQUEST_STATUS.LOADING;
        // });
        // builder.addCase(addMomentAssets.fulfilled, (state, action: any) => {
        //   const res = action?.payload;
        //   if (res?.result) {
        //       state.addMomentAssetsStatus = REQUEST_STATUS.SUCCESS;
        //     state.momentAssets = [...state.momentAssets,...res?.result?.assets];
  
        //   } else {
        //       state.errorMessage = res.message;
        //     state.addMomentAssetsStatus = REQUEST_STATUS.FAILURE;
        //   }
        // });
      
        // builder.addCase(addMomentAssets.rejected, (state, action:any) => {
        //   console.log(action)
        //   const res = action?.payload;
        //   state.errorMessage = res?.message;
        //   state.addMomentAssetsStatus = REQUEST_STATUS.FAILURE;
        // });
  }
})
export const momentsActions = momentsSlice.actions;
export const momentsReducer = momentsSlice.reducer;