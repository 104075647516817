switch (process.env.NEXT_PUBLIC_CURRENT_ENV) {
    case "prod":{
        
      module.exports = require("./prod");
      break;}
      case "stage":
      module.exports = require("./stage")
      break;
    default:
      module.exports = require("./dev");
      break;
  }