import { privateUrl } from "@hooks"
import { assignParticipantProps, EditControlSettingsProps,UpdateMemoryTitleProps } from "./memories.types"
export const assignParticipant =async ({
    loginToken,
    memoryId,
    identifier,
    identifierType,//user
    guestName,//""
    isAdmin
}:assignParticipantProps) => {
    return await privateUrl
      .post(`Memories/AssignParticipant`, {
        memoryId,
        identifier,
        identifierType,
        guestName,
        isAdmin
      }, {
        headers: {
          Authorization: loginToken,
        }
      })
}

export const EditControlSettings =async ({
  loginToken,
  memoryId,
  isPublic,
  allowContribution,
  allowAnonymousAccess,
}:EditControlSettingsProps) => {
  return await privateUrl
    .post(`Memories/EditControlSettings`, {
      id:memoryId,
      controlSettings:{
        isPublic,
        allowContribution,
        allowAnonymousAccess
      }
    }, {
      headers: {
        Authorization: loginToken,
      }
    })
}

// update Memory Title
export const updateMemoryTitle =async ({
  loginToken,
  memoryId,
  title
}:UpdateMemoryTitleProps) => {
  return await privateUrl
    .put(`Memories/${memoryId}`, {
      title:title,
    }, {
      headers: {
        Authorization: loginToken,
      }
    })
}