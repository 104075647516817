import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  authReducer,
  memoriesReducer,
  momentsReducer,
  myContactsReducer,
  discoverReducer,
  globalReducer
} from "./slices";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['global'], // add other reducers you want to persist here
};
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    global: globalReducer,
     auth: authReducer,
     memories:memoriesReducer,
     moments:momentsReducer,
     myContacts:myContactsReducer,
    discover: discoverReducer,

  })
);
/**
 * createStore
 *
 * Features:
 * - Creates a redux store
 */


export const createStore = () => {
  const store =  configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistor = persistStore(store);
  return { store, persistor };
}
// Export store and persistor
const { store, persistor } = createStore();
export { store, persistor };
// RootState and AppDispatch types
export type RootState = ReturnType<typeof persistedReducer>;
export type AppDispatch = typeof store.dispatch;

// export type RootState = ReturnType<typeof createStore.getState>;

// export type AppDispatch = typeof createStore.dispatch;
