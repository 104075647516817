import { environment, experiences } from "@config";
import { axiosInstance } from "@hooks";
import axios from "axios";
import ExperienceById from "../mock/experiences/ExperienceById.json";
import ExperienceByUrl from "../mock/experiences/ExperienceByUrl.json";
import ExperienceRecommendations from "../mock/experiences/ExperienceRecommendations.json";

// TODO: Remove this if below with fetcg caching working properly
// export const getExperienceByUrl = async ({
//   seoUrl,
//   locale,
// }: {
//   seoUrl: string;
//   locale: string;
// }) => {
//   if (experiences.GET_EXPERIENCES_BY_URL.useMock) {
//     return ExperienceByUrl;
//   } else {
//     let baseUrl = environment.apiKey;
//     baseUrl = `${baseUrl}${experiences.GET_EXPERIENCES_BY_URL.url}?url=${seoUrl}`;
    
//     return axios
//       .get(baseUrl, {
//         headers: {
//           "Accept-Language": locale,
//         },
//       })
//       .then((res) => Promise.resolve(res?.data))
//       .catch((err) => Promise.reject(err));
//   }
// };
export const getExperienceByUrl = async ({
  seoUrl,
  locale,
}: {
  seoUrl: string;
  locale: string;
}) => {
  if (experiences.GET_EXPERIENCES_BY_URL.useMock) {
    return ExperienceByUrl;
  } else {
    let baseUrl = environment.apiKey;
    baseUrl = `${baseUrl}${experiences.GET_EXPERIENCES_BY_URL.url}?url=${seoUrl}`;

    try {
      const res = await fetch(baseUrl, {
        headers: {
          'Accept-Language': locale,
        },
        // You can specify other options here like method, body, etc.
      });

      if (!res.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await res.json();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

export const getExperienceById = async ({
  id,
  locale,
}: {
  id: string;
  locale: string;
}) => {
  if (experiences.GET_EXPERIENCES_BY_ID.useMock) {
    return ExperienceById;
  } else {
    let baseUrl = environment.apiKey;
    baseUrl = `${baseUrl}${experiences.GET_EXPERIENCES_BY_ID.url}/${id}`;
    // return await axios.get(baseUrl);
    return axios
      .get(baseUrl, {
        headers: {
          "Accept-Language": locale,
        },
      })
      .then((res) => Promise.resolve(res?.data))
      .catch((err) => Promise.reject(err));
  }
};

// GET_EXPERIENCES_BY_SEARCH
export const getExperiencesBySearch = async ({
  latitude,
  longitude,
  offset,
  limit,
  locale
}: {
  latitude?: number;
  longitude?: number;
  offset: number;
  limit: number;
  locale: string
}) => {
  let baseUrl = "";
  if (experiences.GET_EXPERIENCES_BY_SEARCH.useMock) {
    baseUrl = environment.mockApiKey;
  } else {
    baseUrl = environment.apiKey;
  }
  let query = "";
  query = latitude ? query + `&latitude=${latitude}` : "";
  query = longitude ? query + `&longitude=${longitude}` : "";
  return await axios.get(
    `${baseUrl}${experiences.GET_EXPERIENCES_BY_SEARCH.url}?offset=${offset}&limit=${limit}${query}`,{
      headers: {
        "Accept-Language": locale,
      },
    }
  );
};

// GET_EXPERIENCE_RECCOMENDATIONS
export const getExperienceRecomendations = async ({
  id,
  latitude,
  longitude,
  offset,
  limit,
  activityId,
  locale
}: {
  id: string;
  latitude: number;
  longitude: number;
  limit: number;
  offset: number;
  activityId?: string;
  locale: string;
}) => {
  if (experiences.GET_EXPERIENCES_RECOMMENDATIONS.useMock) {
    return ExperienceRecommendations;
  } else {
    let baseUrl = environment.apiKey;
    let query = "";
    query = latitude ? query + `&latitude=${latitude}` : query;
    query = longitude ? query + `&longitude=${longitude}` : query;
    query = activityId ? query + `&exclude-activity-id=${activityId}` : query;
    return axios
      .get(
        `${baseUrl}${experiences.GET_EXPERIENCES_RECOMMENDATIONS.url}/${id}/recommendations?offset=${offset}&limit=${limit}${query}`,{
          headers: {
            "Accept-Language": locale,
          },
        }
      )
      .then((res) => Promise.resolve(res?.data))
      .catch((err) => Promise.reject(err));
  }
};

export const addNewQuestionForExperience = async ({
  id,
  data,
}: {
  id: string;
  data: { name: string; email: string; question: string };
}) => {
  let baseUrl = environment.apiKey;
  baseUrl = `${baseUrl}/explore/api/v1/experiences/${id}/question`;
  // return await axios.get(baseUrl);
  return axios
    .post(
      baseUrl,
      {
        ...data,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((res) => Promise.resolve(res?.data))
    .catch((err) => Promise.reject(err));
};
export const addNewSuggestionForExperience = async ({
  id,
  data,
}: {
  id?: string;
  data: { name: string; email: string; website: string };
}) => {
  let baseUrl = environment.apiKey;
  baseUrl = `${baseUrl}/explore/api/v1/experiences/suggestion`;
  // return await axios.get(baseUrl);
  return axios
    .post(
      baseUrl,
      {
        id,
        ...data,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((res) => Promise.resolve(res?.data))
    .catch((err) => Promise.reject(err));
};
