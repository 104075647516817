import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthSliceState } from "./auth.types";
import {login,logout} from "./auth-api";
export const authInitialState: AuthSliceState = {
    loggedIn: false,
    logoutError:false,
    loginError: false,
    loading: false,
    user: { 
        email: "",
    fullName: "",
    profileImage: "",
    userName:"",
    id:""
 },
    authtoken: "",
    errorMessage:""
  };
  const authSlice = createSlice({
    name: "auth",
    initialState: authInitialState,
    reducers: {
      logout(state) {
        state.user = authInitialState.user;
        state.loggedIn = false;
        state.authtoken = "";
        localStorage.clear();
      },
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action: any) => {
            const res = action?.payload;
            if (res?.result) {
              state.loading = false;
              state.loggedIn = true;
              state.loginError = false;
              state.authtoken = res?.result?.token?.accessToken;
              localStorage.setItem("token", res?.result?.token?.accessToken);
              localStorage.setItem("refreshToken", res?.result?.token?.refreshToken);
              const {email, fullName,profileImage, userName,id}= res?.result;
              state.user = {
                  email,
                  fullName,
                  profileImage,
                  userName,
                  id
              }
            }
            else {
              state.loginError = true;
            }
          });
          builder.addCase(login.pending, (state, action) => {
            state.loading = true;
          });
          builder.addCase(login.rejected, (state, action:any) => {
            const res = action;
            state.loading = false;
            state.errorMessage = res?.data?.responseException?.exceptionMessage;
          });

          // LOGOUT
          builder.addCase(logout.fulfilled, (state, action: any) => {
            const res = action?.payload;
            if (res?.result) {
              state.user = authInitialState.user;
        state.loggedIn = false;
        state.authtoken = "";
        localStorage.clear();
            } else {
                state.errorMessage = res.message;
              state.logoutError = true;
            }
          });
          builder.addCase(logout.pending, (state, action) => {
            state.loading = true;
          });
          builder.addCase(logout.rejected, (state, action:any) => {
            const res = action;
            state.loading = false;
          });
    }
});
export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;