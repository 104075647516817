import { privateUrl } from "@hooks"
import { TreeItem } from "@mui/lab"
import { searchDataProps } from "./search.types"
export const searchData =async ({
    loginToken,
    item
}:searchDataProps) => {
    return await privateUrl
      .post(`Search`, {item:item,type:1}, {
        headers: {
          Authorization: loginToken,
        }
      })
}
