import systemDevelpmentConfig from "./system.config.dev.json";
import systemStageConfig from "./system.config.stage.json";
import systemProductionConfig from "./system.config.prod.json";

var systemConfig = systemDevelpmentConfig;

if (process?.env?.NEXT_PUBLIC_CURRENT_ENV == "prod") {
  systemConfig = systemProductionConfig;
} else if (process?.env?.NEXT_PUBLIC_CURRENT_ENV == "stage") {
  systemConfig = systemStageConfig;
} else {
  systemConfig = systemDevelpmentConfig;
}
export default systemConfig;
