
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGlobalInitialState, IUrlFromApi, severityType } from "./global.types";


export const globalInitialState: IGlobalInitialState = {
    open:false,
    type:"success",
    message:"",
    urlFromApi:{
         routeKey:null,
         urls: null,
    }
}
const globalSlice = createSlice({
    name: "global",
    initialState: globalInitialState,
    reducers: {
        snackbar(state, action:PayloadAction<{type:severityType, message:string}>){
             const {payload:{type,message}} = action;
             state.open = true;
             state.message = message;
             state.type = type
        },
        closeSnackbar(state){
            state.open = false;
            state.message = "";
            state.type = "success"
        },
        steUrlFromApi(state, action:PayloadAction<IUrlFromApi>){
            state.urlFromApi = action.payload
        }
    },
 
})
export const globalActions = globalSlice.actions;
export const globalReducer = globalSlice.reducer;