import { createAsyncThunk } from "@reduxjs/toolkit";
import { publicUrl,privateUrl } from "@next/hooks";

export const createMemory = createAsyncThunk(
  "memories/createMemory",
  async ({ loginToken }: { loginToken: string }) => {
    return await privateUrl
      .post("Memories", { },{
          headers:{
            Authorization:loginToken
          }
      })
      .then((res) => Promise.resolve(res.data))
      .catch((err) => {
           const res = err?.response.data?.responseException?.exceptionMessage;
        console.log("err.response",res)
        return Promise.reject(res)
      });
  }
);