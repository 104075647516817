module.exports = Object.freeze({
   
    NEXT_PUBLIC_API_KEY : "https://apim-experiences-stage.azure-api.net", //API Gateway
    NEXT_PUBLIC_MOCK_API_KEY: "https://d87ab4cf-5e13-48b9-ba52-7c95e3cde2d7.mock.pstmn.io/v1/",
    NEXT_PUBLIC_GOOGLE_PLACES_KEY : "AIzaSyATwvQtaQ5PH-yucX3UG5tpXml39tslRQ8",
    NEXT_PUBLIC_DOMAIN : "https://dev.marveltime.com",
    PORT: 3000,
    NEXT_PUBLIC_GOOGLE_TAG_MANAGER : "G-18JNDKMTHT",
    SENTRY_ORG: "project-experiences",
    SENTRY_PROJECT:"web-app-core",
    NEXT_PUBLIC_SENTRY_DSN:"https://9a597b5d4d9b5e67cf1f10f350e02420@o4505195662868480.ingest.sentry.io/4505195664113664",
    SENTRY_AUTH_TOKEN: "3d9edb507fe706edbccd706699f40d074d0a5cb67dd67642cd6a412b1f7df9eb"
})