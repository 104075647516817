import { REQUEST_STATUS } from "@constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { discoverSliceState } from "./discover.types";

export const discoverInitialState: discoverSliceState = {
    location:{
        value:"",
        lat:"",
        lng:"",
        placeId:""
    },
    users: []
}
const discoverSlice = createSlice({
    name: "discover",
    initialState: discoverInitialState,
    reducers: {
       setLocation : (state,{payload}) => {
          state.location = payload
       }
    },

});
export const discoverActions = discoverSlice.actions;
export const discoverReducer = discoverSlice.reducer;