import { REQUEST_STATUS } from "@constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MemoriesSliceState } from "./memories.types";
import { createMemory } from "./memories-api";
export const memoriesInitialState: MemoriesSliceState = {
    createMemoryStatus:REQUEST_STATUS.IDEL,
    memory:{},
    errorMessage:""
}
const memoriesSlice = createSlice({
    name: "memories",
    initialState: memoriesInitialState,
    reducers: {},
    extraReducers: (builder) => {
          // add_MEMORY
          builder.addCase(createMemory.pending, (state, action) => {
            state.createMemoryStatus = REQUEST_STATUS.LOADING;
          });
          builder.addCase(createMemory.fulfilled, (state, action: any) => {
            const res = action?.payload;
            if (res?.result) {
                state.createMemoryStatus = REQUEST_STATUS.SUCCESS;
              state.memory = res?.result;
    
            } else {
                state.errorMessage = res.message;
              state.createMemoryStatus = REQUEST_STATUS.FAILURE;
            }
          });
        
          builder.addCase(createMemory.rejected, (state, action:any) => {
            const res = action?.payload;
            state.errorMessage = res?.message;
            state.createMemoryStatus = REQUEST_STATUS.FAILURE;
          });
    }
});
export const memoriesActions = memoriesSlice.actions;
export const memoriesReducer = memoriesSlice.reducer;