import { createAsyncThunk } from "@reduxjs/toolkit";
import { publicUrl, privateUrl } from "@next/hooks";

export const getGroups = createAsyncThunk(
  "myContact/get-groups",
  async ({ loginToken }: { loginToken: string }) => {
    return await privateUrl
      .get("Groups", {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => Promise.resolve(res.data))
      .catch((err) => {
        const res = err?.response.data?.responseException?.exceptionMessage;
        console.log("Error: fetching get groups from API: ", res);
        return Promise.reject(res);
      });
  }
);

export const getContacts = createAsyncThunk(
  "myContact/get-contacts",
  async ({ loginToken }: { loginToken: string }) => {
    return await privateUrl
      .get("Contacts", {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => Promise.resolve(res.data))
      .catch((err) => {
        const res = err?.response.data?.responseException?.exceptionMessage;
        console.log("Error: fetching get contacts from API", res);
        return Promise.reject(res);
      });
  }
);
