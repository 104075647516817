export const SIGN_IN_OTP = "SIGN_IN_OTP";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const REQUEST_STATUS = {
  IDEL: "idel",
  LOADING: "loading",
  SUCCESS: "success",
  FAILURE: "failure",
};

// TODO @Hassam: Read brand name from central config file
export const brand = "Marveltime";
export const urlValidator = /^(https?:\/\/)?[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}(?:\/[^\s]*)?(?:\?.*)?$/;

export  const emojis = [
  {
    src: "/assets/images/rating/smiley-hate.png",
    tag: "hate",
  },
  {
    src: "/assets/images/rating/smiley-disappointed-inactive.png",
    tag: "disappointed",
  },
  {
    src: "/assets/images/rating/smiley-neutral.png",
    tag: "neutral",
  },
  {
    src: "/assets/images/rating/smiley-good.png",
    tag: "godd",
  },
  {
    src: "/assets/images/rating/smiley-excellent.png",
    tag: "excellent",
  },
];