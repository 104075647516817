import { createAsyncThunk } from "@reduxjs/toolkit";
import { publicUrl, privateUrl } from "@next/hooks";

export const login = createAsyncThunk(
  "auth/login",
  async ({
    identifier,
    password,
  }: {
    identifier: string;
    password: string;
  }) => {
    return await publicUrl
      .post("Accounts/Login", { identifier, password })
      .then((res) => Promise.resolve(res.data))
      .catch((err) => {
        const res = err?.response.data?.responseException?.exceptionMessage;
        console.log("Error: account login", res);
        return Promise.reject(res);
      });
  }
);

// Logout api
export const logout = createAsyncThunk("auth/logout", async () => {
  return await privateUrl
    .get("Accounts/Logout")
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err));
});
